<template>
  <component :is="contentComponent" :themeConfig="themeConfig" :showDebug="showDebug"
    :deviceType="deviceType" :host="host" :adsense="adsenseConfig" :domainConfig="domainConfig" />
</template>

<script>

const theme_template = () => import('@/pages/template/themeDouble.vue') //代码切割
const beesads_template = () => import('@/pages/template/beesads.vue') //代码切割
import domainList from '@/utils/config/beesDomain.js'; // 导入域名列表
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  computed: {
    ...mapGetters([
      'themeConfig',
      'domainConfig',
      'showDebug',
      'deviceType',
      'host',
      'adsenseConfig'
    ])
  },
  created () {
    const host = this.$store.state.host
    if (domainList.includes(host)) {
      this.contentComponent = beesads_template
      return
    }
    this.contentComponent = theme_template
  },

};
</script>
