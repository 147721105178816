<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.path" />
    </keep-alive>
  </div>
</template>

<script>
import '@/css/main.scss';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters(['adsenseConfig']),
  },
  mounted () {
    this.initAdsense();
  },
  methods: {
    // 初始化广告脚本逻辑
    initAdsense () {
      const config = this.adsenseConfig; // 获取当前域名的广告配置
      if (!config) {
        console.error('未找到当前域名的广告配置');
        return;
      }

      // 加载主广告脚本
      this.loadScript(config.scriptUrl, () => {
        console.log('广告脚本加载完成');
        this.insertPublicAd(config.public);
      });
    },
    // 动态加载广告脚本
    loadScript (url, callback) {
      const existingScript = document.querySelector(`script[src="${url}"]`);
      if (existingScript) {
        console.log('广告脚本已存在，无需重新加载');
        if (callback) callback();
        return;
      }

      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      script.onerror = () => {
        console.error(`广告脚本加载失败：${url}`);
      };
      document.head.appendChild(script);
    },
    // 插入公共广告逻辑
    insertPublicAd (publicScript) {
      if (!publicScript) {
        console.warn('公共广告脚本为空，跳过插入');
        return;
      }

      try {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = publicScript;
        document.head.appendChild(script);
        console.log('公共广告脚本插入完成');
      } catch (error) {
        console.error('公共广告脚本插入失败', error);
      }
    },

  },
};
</script>