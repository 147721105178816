<template>
  <section class="land-feed-container">
    <!-- feed 顶部广告 -->
    <AdComponent v-if="themeConfig.landFeedAdPos.includes(0)" adType="land_feed_ad"
      :adPosition="0" />

    <div class="feed-title">
      Recommend
    </div>
    <van-list v-model="loading" :finished="finished" :finished-text="bomTitle" :offset="20"
      class="land-feed" loading-text="Loading..." :immediate-check="false" @load="onLoad">
      <div class="newlist_row">
        <template v-for="(item, index) in adjustedItems">
          <a v-if="item.adtype === 'component'" :key="`key_${index}`"
            :href="`/landing?id=${item.data.id}&thme=${themeConfig.landType}`"
            class="feed-item-link" @click="trackClick(item.data.id)">
            <component :is="item.componentType" :item="item.data"
              :class="[`item-theme-${item.data.type === 1 ? 'lead' : 'collection'}`, invalidClass(item.data.type === 1 ? 'lead' : 'collection')]" />
          </a>
          <AdComponent v-else-if="item.adtype === 'ad'" :ref="`ads_pos_${item.position}`"
            :key="`key-ad_${index}`" class="ad-row" adType="land_feed_insert_ad"
            :adPosition="item.position" />
        </template>
      </div>
    </van-list>

    <!-- feed 底部广告 -->
    <AdComponent v-if="themeConfig.landFeedAdPos.includes(2)" adType="land_feed_ad"
      :adPosition="2" />
  </section>
</template>

<script>
import adjustedItemsMixin from '@/mixin/adjustedItemsMixin.js';
import AdComponent from '@/pages/components/AdComponentDouble.vue'

export default {
  components: { AdComponent },
  mixins: [adjustedItemsMixin],
  computed: {
    adjustedItems () {
      return this.getAdjustedItems(this.themeConfig, this.list, 'landFeedInsertAdPos', 'landFeedLeadType', 'landFeedCollectionType', 2);
    }
  },
  async mounted () {
    console.log('feeeeeeed-------------------mounted')
    this.initData()
  },
  methods: {
    invalidClass (type) {
      if (type === 'lead' && !this.themeConfig.landFeedLeadType) {
        return 'params-invalid'
      }
      if (type === 'collection' && !this.themeConfig.landFeedCollectionType) {
        return 'params-invalid'
      }
      return ''
    },
    getAdConfig (position) {
      console.log(this.adsense);
      return this.adsense[`land_feed_insert_ad_${position}`] || {};
    },
    trackClick (id) {
      // 事件追踪
      if (typeof this.$eventrackFb === 'function') {
        this.$eventrackFb('news_goto_detail', 'click', {
          id
        });
      }
      // 通知父组件
      this.$emit('feedClicked', id);
    },
    async initData () {
      console.log('loadList');
      try {
        await this.getList();
      } catch (error) {
        console.error('An error occurred in getList:', error);
      }
    },
  },
};
</script>

<style scoped>
.feed-item-link {
	display: block;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

/* 保持原有的hover效果 */
.feed-item-link:hover {
	opacity: 0.9;
}
</style>